@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Poppins&family=Work+Sans:wght@300&display=swap");

.loginSuccessContainer {
	display: grid;
	place-items: center;
	height: 100vh;
	position: relative;
}

.loginSuccessContainer > div {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.login_1_heading {
	margin-left: 70px;
	width: 133px;
	height: 51px;
	top: 356px;
	left: 113px;
	font-family: Poppins;
	font-size: 34px;
	font-weight: 500;
	line-height: 51px;
	letter-spacing: 0em;
	text-align: left;
	background-image: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

.div1 {
	width: 277px;
	height: 44px;
	top: 428px;
	left: 42px;
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 600;
	/* line-height: 10px; */
	letter-spacing: 0em;
	text-align: center;
	color: rgba(27, 27, 27, 1);
}

.divContainer {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

.NeedSupport {
	width: 133px;
	height: 40px;
	border-radius: 20px;
	border: 1px;
	opacity: 0.5px;
	background: linear-gradient(0deg, #d9d9d9, #d9d9d9);
	border: 1px solid rgba(0, 0, 0, 0.5);
	font-family: Open Sans;

	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	/* margin-left: 100px; */
}

.bottomDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.selectinput {
	display: flex;
	gap: 10px;
}

.selectinput .PhoneInput {
	width: 100%;
}

.selectinput .PhoneInputCountrySelect {
	width: 12vh;
	background: rgba(247, 247, 247, 1);
	border: 2px solid rgba(226, 226, 226, 1);
}

.selectinput input {
	padding-left: 21px;
}

.needSupportbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
}
