.proflieDisplay {
	width: 100%;
	padding: 20px 0;
}

.imageBox {
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto 10px;
	height: 100px;
	border-radius: 100%;
	padding: 2px;
	overflow: hidden;
	background: linear-gradient(180deg, #268fc1 0%, #62c3ca 100%);
}
.imageBox p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font: 500 40px/60px "PoppinLightBold";
	color: white;
	text-transform: uppercase;
}
.imageBox img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 100%;
}

.name {
	width: 100%;
	text-align: center;
	font: 500 18px/20px "Poppins", sans-serif;
	color: rgba(119, 119, 119, 1);
}

.navigationBox {
	width: 100%;
	border-radius: 12px;
	margin: 20px auto 0;
	padding: 10px 10px 15px 10px;
	box-sizing: border-box;
	display: flex;
	border-radius: 4px;
	border-bottom: 1px solid rgba(219, 219, 219, 1);
	justify-content: space-between;
	align-items: center;
}
.navigationBox div {
	display: flex;
	align-items: center;
	width: 80%;
}
.navigationBox div h2 {
	font: 500 16px/18px "Poppins", sans-serif;
	margin: 0 0 0 8px;
}

.yesNoBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.yesNoBox p {
	display: inline-block;
	width: 45%;
	border-radius: 8px;
	background: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%),
		linear-gradient(0deg, #319bda, #319bda);
	color: white;
	padding: 4px 0;
	margin: 5px auto;
}
