@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

@font-face {
	font-family: MontserratBold;
	src: url("../src/assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf");
}
@font-face {
	font-family: MontserratLightBold;
	src: url("../src/assets/fonts/Montserrat/static/Montserrat-Bold.ttf");
}
@font-face {
	font-family: Poppins;
	src: url("../src/assets/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
	font-family: PoppinLightBold;
	src: url("../src/assets/fonts/Poppins/Poppins-SemiBold.ttf");
}
*::-webkit-scrollbar {
	display: none;
}
html {
	height: 100%;
}
body {
	margin: 0;
	padding: 0;
	height: 100%;
}
#root {
	background: #fff;
	height: 100%;
	width: 100%;
	max-width: 500px;
	margin: 0 auto 0 0;
	overflow: hidden scroll;
	box-sizing: border-box;
}

#root::-webkit-scrollbar {
	display: none;
}
.App {
	color: black;
	position: relative;
	user-select: none;
	background-image: url("../src/assets/images/backgroundpattern.png");
	background-size: contain;
	min-height: 100vh;
	background-position: center;
}
.flex {
	display: flex;
	align-items: center;
}

.categoryContainer,
.vouchercontainer,
.navContainer,
.container {
	width: 100%;
	padding: 10px 10px;
	position: relative;
	box-sizing: border-box;
	height: 100%;
	min-height: calc(100vh - 80px);
}
.categoryContainer {
	padding-bottom: 100px;
	height: auto;
	display: flex;
	overflow: scroll;
	flex-direction: column;
	min-height: auto;
}
.catgeoryName {
	width: 100%;
	color: #127ebe;
	font: 500 16px/18px "PoppinsBold", sans-serif;
	padding-left: 10px;
}
.categorySlider {
	width: 100%;
	display: flex;
	flex-direction: row;
	position: relative;
}

.allVouchers {
	display: flex;
	flex-wrap: wrap;
	/* overflow: scroll; */
	width: 100%;
}
.allVouchers::-webkit-scrollbar {
	display: none;
}
.dFlex {
	display: flex;
	flex-wrap: wrap;
}

.w50 {
	width: 49%;
	box-sizing: border-box;
	padding: 15px 15px 5px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.w50.widthFit {
	width: fit-content;
}
@media screen and (max-width: 400px) {
	.w50 {
		padding: 10px;
	}
}
.w70 {
	width: 70%;
	box-sizing: border-box;
	padding: 15px 15px 5px;
}
.w30 {
	width: 30%;
	box-sizing: border-box;
	padding: 15px 15px 5px;
}
.p15 {
	padding: 15px 15px 5px;
}
.voucher {
	margin: 0 auto 11px 10px;
	padding: 10px 10px 4px;
	background-color: white;
	border-radius: 15px;
	text-align: center;
	cursor: pointer;
	transition: 0.3s ease-in-out;
	width: 44%;
	box-sizing: border-box;
	border: 1px solid #eaeaea;
	box-shadow: 0px 1px 1px 0px #00000036;
}

.voucherImage {
	width: 100%;
	height: 120px;
	border-radius: 20px;
	justify-content: center;
	margin: 0 auto;
	/* box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.09);
	background-color: rgba(249, 207, 207, 0.2); */
}
.voucherImage img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}
.voucherDetail {
	margin-top: 13px;
}
.voucherDetail p {
	margin: 0px 0;
	text-align: left;
}
.voucherPrice {
	font: 700 16px/18px "Poppins", sans-serif;
}
.voucherAmount {
	font: 400 14px/17px "Poppins", sans-serif;
	max-height: 20px;
	color: rgba(139, 139, 139, 1);
	overflow: hidden;
}
.voucherName {
	font: 400 10px/15px "Poppins", sans-serif;
	word-break: break-all;
	max-height: 17px;
	overflow: hidden;
	color: #1f1f1f;
}
.expiryTime {
	font: 400 10px/14px "Poppins", sans-serif;
	color: rgba(139, 139, 139, 1);
}

.vouchercontainer {
	padding: 0px 0 100px;
}
.voucherBox {
	width: 100%;
	box-sizing: border-box;
	border-bottom: 2px solid rgba(234, 234, 234, 1);
}
.voucherBox.dFlex {
	padding-bottom: 10px;
}
.imageCover {
	max-width: 150px;
	max-height: 150px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
}
.imageCover img {
	width: 100%;
}

.vName {
	width: 100%;
	text-align: left;
	margin: 10px 0;
	font: 500 18px/21px "PoppinLightBold", sans-serif;
}
.vName.colorBlend {
	color: #8b8b8b;
	font: 600 14px/18px "Poppins", sans-serif;
}

.valueHeading {
	font: 500 18px/21px "Poppins", sans-serif;
	margin: 6px 0;
}

.valueGrid {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	overflow: scroll;
	margin-top: 10px;
	box-sizing: border-box;
}

.value {
	min-width: 120px;
	width: 120px;
	background-color: rgba(243, 243, 243, 1);
	border-radius: 5px;
	padding: 10px 0;
	margin: 0 15px 10px 0;
	cursor: pointer;
}
.value p {
	font: 500 14px/18px "Poppins", sans-serif;
	width: 100%;
	text-align: center;
	margin: 3px 0;
}

.value.active {
	background: linear-gradient(
		93deg,
		rgba(18, 126, 190, 1) 15%,
		rgba(105, 201, 202, 1) 89%
	);
	color: #fff;
}

.proceedButton {
	width: 100%;
	box-sizing: border-box;
	padding: 15px;
	position: fixed;
	padding: 15px;
	bottom: 0%;
	max-width: 500px;
}

.payBox {
	background: rgb(18, 126, 190);
	background: linear-gradient(
		93deg,
		rgba(18, 126, 190, 1) 15%,
		rgba(105, 201, 202, 1) 89%
	);
	width: -moz-fit-content;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	padding: 14px 35px;
	font: 500 14px/18px "Poppins", sans-serif;
	color: white;
	margin: 0 auto;
	border-radius: 5px;
	cursor: pointer;
}

.gateways {
	max-width: 100%;
	width: auto;
	margin: 10px;
	justify-content: space-between;
	align-items: center;
	padding: 12px 14px;
	border: 1px solid rgba(226, 226, 226, 1);
	box-sizing: border-box;
	background-color: white;
	border-radius: 10px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
.dFlex.align {
	align-items: center;
}
.app_Pic {
	width: 45px;
	height: 45px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 5px;
}
.circle {
	border-radius: 100%;
	border: 2px solid rgba(168, 168, 168, 1);
	height: 18px;
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app_Pic img {
	width: 100%;
	height: 100%;
}

.paymentName {
	font: 500 14px/18px "Poppins", sans-serif;
}

.w500 {
	width: 100%;
	max-width: 500px;
}

.popup {
	width: 100%;
	min-width: 200px;
}

.popup h3 {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	font: 500 18px/22px "Poppins", sans-serif;
}

.okayButton {
	width: fit-content;
	margin: 10px auto;
	font: 500 16px/18px "Poppins", sans-serif;
	padding: 10px 30px;
	color: white;
	text-align: center;
	border-radius: 10px;
	background-color: rgba(18, 126, 190, 1);
}

.inputBox {
	box-sizing: border-box;
}
.inputBox p {
	width: 100%;
	padding: 10px;
	margin: 0;
	box-sizing: border-box;
	color: #aebebc;
	font: 400 14px/20px "Poppins", sans-serif;
}
.inputBox .profileInput {
	width: calc(100% - 20px);
	height: 100%;
	padding: 10px;
	background-color: #f9fbfb;
	outline: none;
	border: 1px solid #e1e6ea;
	margin: 0 10px;
	color: black;
	box-sizing: border-box;
	font: 400 14px/20px "Poppins", sans-serif;
}
.pRelative {
	position: relative;
}

.edit {
	width: calc(100% - 20px);
	background-color: #127ebe;
	padding: 10px;
	font: 400 14px/20px "Poppins", sans-serif;
	text-align: center;
	margin: 20px 10px;
	box-sizing: border-box;
	color: white;
}

.editIcon {
	position: absolute;
	top: 30%;
	color: rgb(153, 164, 163);
	right: 5%;
	width: 14px;
	transform: translate(-30%, -10%);
}

.w100 {
	width: 100%;
	padding: 0 15px;
	font: 600 14px/20px "Poppins", sans-serif;
}

.newinputTag {
	width: 100%;
	box-sizing: border-box;
	padding: 17px 10px;
	border: none;
	background-color: #edf2f2;
}
.newinputTag:focus-visible {
	outline: #8b8b8b5a auto 1px;
}

.newinputTag::placeholder {
	color: #8b8b8b;
	font: 400 14px/20px "Poppins", sans-serif;
}

.w100 p {
	margin-bottom: 5px;
}
.errorMsg {
	width: 100%;
	font: 600 12px/20px "Poppins", sans-serif;
	color: red;
	margin: 0;
}

.flex.alignSpace {
	justify-content: space-between;
}
.viewAllOption {
	font: 600 12px/20px "Poppins", sans-serif;
	color: #127ebe;
	cursor: pointer;
	margin: 0;
}

.dialogs {
	width: 100%;
	max-width: 500px;
}

.viewAllPrice {
	background-color: white;
	padding: 20px 16px;
	max-width: 400px;
	width: fit-content;
	min-width: 300px;
	margin: 0 auto;
	box-sizing: border-box;
}

.viewALlPriceHeading {
	font: 500 17px/24px "PoppinLightBold", sans-serif;
	color: #2f3336;
	margin: 0 0 10px;
}

.doneButton {
	background: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%),
		linear-gradient(0deg, #319bda, #319bda);
	border-radius: 5px;
	color: white;
	width: 100%;
	padding: 10px 0;
	text-align: center;
	margin: 0%;
	cursor: pointer;
	font: 600 16px/24px "Poppins", sans-serif;
}
.vieALlValue .value {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	background-color: transparent;
	border-bottom: 1px solid rgba(234, 234, 234, 1);
}
.vieALlValue .value p {
	text-align: left;
}
.vieALlValue .value.active {
	background-color: rgba(49, 155, 218, 1);
	color: white;
}

.span {
	width: 20px;
}

.span svg {
	transition: all 0.2s ease-in-out;
	transform: rotate(90deg);
}
.voucherBox.p15 {
	transition: all 12s ease-in-out;
}
.voucherBox.p15.last {
	padding-bottom: 80px;
}
.span.active svg {
	transform: rotate(-90deg);
}
.valueHeading {
	font: 600 14px/20px "Poppins", sans-serif;
	margin: 0;
}

.voucherDescCover p.vName {
	font: 400 16px/25px "Poppins", sans-serif;
	color: rgba(139, 139, 139, 1);
}

.quantity {
	display: block;
	background-color: white;
	border: 1px solid black;
	border-radius: 5px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

.quantity span {
	width: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px;
	text-align: center;
	position: relative;

	font: 500 16px/25px "Poppins", sans-serif;
}
.quantity span::after {
	content: "";
	position: absolute;
	top: 20%;
	right: -1px;
	width: 1px;
	height: 60%;
	border-left: 1px solid black;
}

.quantity span:last-child::after {
	display: none;
}

.pb15 {
	padding-bottom: 15px;
}

.processScreen {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.paymentProcessing {
	width: 100%;
	display: block;
	position: relative;
}

.processing {
	width: 90px;
	height: 90px;
	margin: 10px auto;
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.processing.pro {
	width: 90px;
	height: 90px;
	margin: 10px auto;
	position: initial;
	transform: none;
}
.processing.setting {
	top: -35%;
}
.processing.tick {
	position: relative;
	top: unset;
	margin: 0 0 -20px;
}
.processing.setting img,
.processing.pro img {
	width: 100%;

	animation: rotation 1s infinite;
}
.processing.tick img {
	animation: none;
}
.payBox.w90 {
	margin-top: 35px;
	width: 80%;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.processHead {
	font: 500 25px/25px "Poppin", sans-serif;
	text-align: center;
	margin: 20px 0 10px;
	width: 100%;
}

.paymentProcessing p {
	font: 600 19px/29px "Poppins", sans-serif;
	color: #000;
	width: 90%;
	margin: 25px auto;
	text-align: center;
}
.centreProcess {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: calc(100vh - 300px);
}
.refreshText {
	font: 600 15px/25px "Poppins", sans-serif;
	text-align: center;
	width: 90%;
	margin: 0 auto;
}
.voucherDetailCover {
	width: 100%;
	padding: 5px 0;
}

.detailBox {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.detailBox p {
	font: 400 15px/20px "Poppins", sans-serif;
	color: rgba(121, 120, 120, 1);
	margin: 3px 0;
}

.voucherNew {
	width: 100%;
	background: rgb(18, 126, 190);
	background: linear-gradient(
		93deg,
		rgba(18, 126, 190, 1) 15%,
		rgba(105, 201, 202, 1) 89%
	);
	padding: 0 12px 8px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	position: relative;
	top: -2px;
}

.voucherNew p {
	margin: 0;
	padding: 10px 0;
	color: white;
	font: 600 17px/20px "Poppins", sans-serif;
}

.w50 p {
	font: 400 14px/18px "Poppins", sans-serif;
	color: rgba(121, 120, 120, 1);
	margin: 0;
}

.order {
	border-bottom: 1px solid rgba(121, 120, 120, 1);
}

.order p {
	font: 400 17px/18px "Poppins", sans-serif;
	color: rgba(121, 120, 120, 1);
	margin: 10px;
}

.order h3 {
	font: 600 20px/20px "Poppins", sans-serif;
	color: #000;
	margin: 15px 10px;
}

.workCover {
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
}
.privacyHeight {
	width: 30px;
	height: 30px;
}

.workCover p,
.workCover ul li {
	font: 500 15px/20px "Poppins", sans-serif;
	color: #000;
	margin: 10px 0;
}
.workCover p b,
.workCover strong {
	font-family: PoppinLightBold;
}

.workCover h1,
.workCover h2,
.workCover h3 {
	font: 700 19px/20px PoppinLightBold;
	color: #000;
	margin: 10px 0;
}
.boxCover.pRelative {
	position: relative;
}
.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	max-width: 500px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	box-sizing: border-box;
	box-shadow: 0px -1px 3px 0px #00000061;
	background-color: white;
}

.footerBox {
	display: block;
	text-align: center;
	width: 30%;
}
.footer h6 {
	font: 300 12px/17px "Poppins", sans-serif;
	color: black;
	margin: 0;
}

.footerBox svg {
	border-radius: 100%;
}

.not {
	position: absolute;
	top: 40%;
	font: 600 20px/27px "Poppins", sans-serif;
	color: black;
	margin: 0;
	text-align: center;
}

.categoryEditBox {
	padding: 20px;
	background-color: #fff;
	width: 100%;
	position: relative;
	text-align: center;
	text-transform: capitalize;
	box-sizing: border-box;
}

.categoryEditBox p {
	font: 600 17px/27px "Poppins", sans-serif;
}

.pending {
	width: 70px;
}
.top.flex p {
	width: 100px;
	font: 500 16px/16px "Poppins", sans-serif;
	margin: 0;
	text-align: right;
	color: rgba(112, 120, 126, 1);
}

.warnPara {
	width: 100%;
	text-align: center;
	color: red;
	font: 500 18px/24px "Poppins", sans-serif;
}
.greenPara {
	width: 100%;
	text-align: center;
	color: #5ab548;
	font: 500 18px/24px "Poppins", sans-serif;
}
