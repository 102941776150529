@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Work+Sans:wght@300&display=swap");

.login {
	display: flex;
	place-items: center;
	flex-direction: column;
	height: 100vh;
	align-items: center;
	justify-content: center;
	position: relative;
}

.login_logo > button {
	width: 300px;
	background-color: #2e81f4;
	color: #eef2f5;
	font-weight: 800;
}

.login_logo > button:hover {
	background-color: white;
	color: #2e81f4;
}

.login > div > img {
	object-fit: contain;
	height: 150px;
}

.login .login_logo {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin-bottom: 10%;
	height: 390px;
}

.loginButton {
	width: 200px;
	padding: 10px 30px;
	font: 500 16px/18px "Poppins", sans-serif;
	text-align: center;
	border: 1px solid black;
	margin: 10px auto;
}

/* ======================================= */

.top__heading {
	width: 102px;
	height: 39px;
	font: 500 26px/39px Poppins;
	letter-spacing: 0em;
	text-align: left;
	background-image: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	position: absolute;
	top: 5%;
	left: 7%;
}

.login__heading {
	position: relative;
	margin-bottom: 15%;
}

.prelative {
	position: relative;
}
.login__to {
	font-family: Open Sans;
	font-size: 30px;
	font-weight: 600;
	/* line-height: 27px; */
	letter-spacing: 0em;
	text-align: left;
	width: 100%;
}

/* .login__heading h3{
	width: 100%;
	font-size: 40px;
} */

.login__para {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;
	position: absolute;
	top: 63px;
	font-size: 15px;
	font-weight: 400;
}

.mobile-input-box {
	width: 308px;
	/* height: 37px; */
	/* top: 257px;
	left: 26px; */
	margin-bottom: 40px;
	/* padding-left: 16px; */
	border-radius: 13px;
	/* border: 1px solid rgba(226, 226, 226, 1); */
}

.sent-otp {
	text-align: center;
	width: 100%;
	height: 40px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #319bda;
	background: linear-gradient(93deg, #127ebe 0.58%, #69c9ca 99.61%);
	margin: 2% 0;
	cursor: pointer;
	font-size: 15px;
}

div .sent-otp-button {
	color: white;
	font-family: Poppins;
	text-transform: capitalize;
}

.google-Login {
	text-align: center;
	width: 308px;
	height: 35px;
	flex-shrink: 0;
	border-radius: 7px;
	background: #fcfcfc;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
	color: rgba(252, 252, 252, 1);
	margin-bottom: 40px;
}

.google-login-button {
	color: #222;
	text-align: center;
	font-family: Open Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	/* 15.6px */
	margin-top: 20px;
}

hr {
	width: 266px;
	height: 0.5px;
	background: #b9b9b9;
}

.not-registered-p {
	display: flex;
	margin-top: -9px;
	margin-bottom: 70px;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
}

.para-one {
	color: #2a2a2a;
	text-align: center;
	font-family: Open Sans;
	font-style: normal;
	margin-right: 3px;
}

.para-two {
	color: #268ab9;
	text-align: center;
	font-family: Open Sans;
	font-style: normal;
}

div .bottom-para {
	text-align: center;
	color: #2a2a2a;
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}

.errorMessage {
	color: red;
	position: absolute;
	top: 55%;
	left: 26%;
	font: 400 10px/12px Open Sans;
	letter-spacing: 0em;
	text-align: center;
}

.sdfs-login {
	display: flex;
	justify-content: space-between;
	margin-top: 4%;
}

.sdfs-login :nth-child(2) {
	color: rgba(38, 138, 185, 1);
	font-family: Open Sans;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
}

.flex_class {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin-bottom: 15%;
}

.flex_class.login-otp {
	display: flex;
	flex-direction: column;
	gap: 20%;
}

.flex-gap-2-login {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.flex-gap-2-login.login-otp-box {
	margin-top: 13%;
}

.otp_div {
	width: 134px !important;
	height: 25px !important;
	font-family: Open Sans;
	font-size: 18px;
	font-weight: 600;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 70px;
}

.send_via_sms-login {
	font-family: Open Sans;
	font-size: 13px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	text-align: left;
}

.auto-fill-login-otp {
	font-family: Open Sans;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 1%;
}

.auto-fill-login-otp p {
	margin: 2px 0px;
}
.otpBox {
	width: 36px !important;
	height: 42px;
	border: 1px solid rgba(226, 226, 226, 1);
	border-radius: 13px;
	background: linear-gradient(0deg, #ffffff, #ffffff);
}

.otp-submit {
	text-align: center;
	width: 308px;
	height: 35px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #319bda;
	background: linear-gradient(93deg, #127ebe 0.58%, #69c9ca 99.61%);
	margin-top: 60px;
}

.flex_class .otp-submit {
	color: white;
	width: 100%;
}
.flex_class .otp-submit .submit-otp-button {
	color: white;
	width: 100%;
}

.loginbottomDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 85%;
}

.otp_div.part2 {
	color: #2a2a2a;
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	width: 100% !important;
	line-height: normal;
	color: rgba(42, 42, 42, 1);
	margin-bottom: 2px;
	margin-top: 30px;
}

.login-verify-otp-text {
	font-size: 35px;
	width: 100%;
}

.selectinput.rel {
	position: relative;
}

.submit-otp-button.small {
	text-transform: inherit;
}

.login-verify-otp-text span {
	font-size: 15px;
}

.otp_div div span {
	font-size: 15px;
}

.mail_login {
	width: 100%;
}
.mail_login img {
	width: 100%;
}

.or_p {
	position: relative;
	width: 100%;
	margin: 10px 0;
}

.or_p p {
	position: relative;
	text-align: center;
	font: 400 14px/16px "Poppins";
}
.or_p p::after {
	content: "";
	width: 45%;
	height: 1px;
	border-top: 1px solid rgba(185, 185, 185, 1);
	position: absolute;
	top: 45%;
	left: 0;
}

.or_p p::before {
	content: "";
	width: 45%;
	height: 1px;
	border-top: 1px solid rgba(185, 185, 185, 1);
	position: absolute;
	top: 45%;
	right: 0;
}

@media screen and (max-width: 400px) {
	.login__para {
		position: relative;
		top: auto;
	}
	.login__to {
		font-family: Open Sans;
		font-size: 23px;
		line-height: 25px;
		font-weight: 600;
		letter-spacing: 0em;
		text-align: left;
		width: 100%;
	}
}
