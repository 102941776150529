@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Poppins&family=Work+Sans:wght@300&display=swap');


.loginSuccessContainer {
    display: grid;
    place-items: center;
    height: 100vh;
    position: relative;
}

.loginSuccessContainer>div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login_1_heading {
    margin-left: 70px;
    width: 133px;
    height: 51px;
    top: 356px;
    left: 113px;
    font-family: Poppins;
    font-size: 34px;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    background-image: linear-gradient(93.37deg, #127EBE 0.58%, #69C9CA 99.61%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.div1 {
    width: 277px;
    height: 44px;
    top: 428px;
    left: 42px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    /* line-height: 10px; */
    letter-spacing: 0em;
    text-align: center;
    color: rgba(27, 27, 27, 1);


}


.divContainer{
    display: flex;
    flex-direction: column;
    gap: 60px;

}

