.singleVoucher {
	width: 100%;
	border-bottom: 1px solid rgba(227, 227, 227, 1);
	padding: 10px 0;
}

.top {
	justify-content: space-between;
}

.bottom {
	justify-content: space-between;
	margin-top: 5px;
}
.topName {
	font: 500 16px/18px "Poppins", sans-serif;
	width: 100%;
	margin: 0px 0 3px 0;
}

.conatinCouponBox {
	width: 155px;
	border-radius: 10px;
	background-color: rgba(18, 126, 190, 1);
	padding: 10px 0;
	cursor: pointer;
	border: 1px solid rgba(18, 126, 190, 1);
}
.conatinCouponBox.open {
	background-color: #e8f2f9;
	border-style: dashed;
	cursor: auto;
	user-select: auto;
}

.view {
	font: 500 13px/16px "Poppins", sans-serif;
	color: white;
	text-align: center;
	margin: 0;
}
.shown {
	font: 600 12px/16px "Poppins", sans-serif;
	color: rgba(18, 126, 190, 1);
	user-select: text;
}
.middle {
	width: 100%;
}

.middle p {
	font: 500 13px/16px "Poppins", sans-serif;
	margin: 4px 0 0 0;
	color: rgba(112, 120, 126, 1);
}

.middle.orderId p {
	font: 500 13px/16px "Poppins", sans-serif;
	margin: 4px 0 0 0;
	color: rgba(112, 120, 126, 1);
}
.topDesc {
	width: 100%;
}

.topDesc h6 {
	margin: 0;
	color: rgba(22, 196, 22, 1);
	font: 500 12px/14px "Poppins", sans-serif;
}
.topDesc h6.error {
	color: rgba(196, 22, 22, 1);
}
.topDesc h6 span {
	color: rgba(112, 120, 126, 1);
	margin-left: 10px;
}
.topDesc h6 span.time {
	margin-left: 0;
}
