.header {
	width: 100%;
	/* background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09); */
	padding: 15px 10px;
	box-sizing: border-box;
}
.header.bgTrue {
	background: rgb(18, 126, 190);
	background: linear-gradient(
		93deg,
		rgba(18, 126, 190, 1) 15%,
		rgba(105, 201, 202, 1) 89%
	);
}
.flex {
	display: flex;
	align-items: center;
}

.headArea {
	width: 100%;
	justify-content: space-between;
}

.backBox {
	width: 40px;
	height: 40px;
	background-color: white;
	cursor: pointer;
	border-radius: 10px;
	box-shadow: 0px 1px 2px 0 #00000070;
	display: flex;
	align-items: center;
}
.backBox img {
	width: 20px;
	margin: 0 auto;
	display: block;
	height: 20px;
}

.heading {
	padding: 0 0 0 10px;
	font: 400 16px/18px "Poppins", sans-serif;
	color: rgb(18, 126, 190);
}

.heading.bgTrue {
	color: white;
}
.profileBox {
	width: 45px;
	height: 45px;
	background-color: black;
	border-radius: 100%;
	overflow: hidden;
	margin: 0 4px;
	cursor: pointer;
}

.profileBox img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.coins {
	border: 1px solid rgba(18, 126, 190, 1);
	height: 45px;
	padding: 5px 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	border-radius: 25px;
	margin-right: 15px;
}

.coins.b0 {
	border: 0;
	justify-content: end;
	margin-right: 0%;
	padding-right: 0;
}
.coinImg {
	width: 30px;
	height: 30px;
	margin-right: 5px;
}

.menuIcons {
	font-size: 1.6rem !important;
	position: relative;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
}
