@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Poppins&family=Work+Sans:wght@300&display=swap");

.topcontainer {
	/* border: 1px solid red; */
	/* text-align: center; */
	margin: 20px;
	margin-top: 30px;
}

.containerTwo {
	/* border: 1px solid red; */
	display: flex;
	/* justify-content: center; */
	flex-direction: column;
	gap: 70px;
	/* height: 100vh;
    width: 100vw; */
}

.Topupxheading {
	width: 102px;
	height: 39px;
	top: 77px;
	left: 25px;
	font-family: Poppins;
	font-size: 26px;
	font-weight: 500;
	line-height: 39px;
	letter-spacing: 0em;
	text-align: left;
	background-image: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

.secondbox .signupLine {
	width: 70%;
	height: 27px;
	top: 186px;
	left: 25px;
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(42, 42, 42, 1);
}

.para1 {
	width: 277px;
	height: 14px;
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(42, 42, 42, 1);
	margin-top: 6px;
}

.inputbox {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.inputbox input {
	/* width: 308px; */
	height: 37px;
	top: 263px;
	left: 25px;
	border-radius: 13px;
	border: 2px solid rgba(226, 226, 226, 1);

	background: linear-gradient(0deg, #ffffff, #ffffff);
}

.selectCountry {
	width: 30%;
	height: 37px;
	/* border: 2px solid rgba(226, 226, 226, 1); */
	border-radius: 10px;
	background: linear-gradient(0deg, #ffffff, #ffffff);
}

.entermobile {
	width: 60%;
	height: 37px;
	top: 361px;
	left: 97px;
	border-radius: 13px;
	border: 2px solid rgba(226, 226, 226, 1);
	background: linear-gradient(0deg, #ffffff, #ffffff);
}

.selectinput {
	display: flex;
	gap: 10px;
}

.selectinput .PhoneInput {
	width: 100%;
}

.inputchekbox {
	width: 15.75px;
	height: 15.75px;
	top: 2.63px;
	left: 2.63px;
	border-radius: 3.5px;
	border: 1.31px;

	background: rgba(255, 255, 255, 1);
	border: 1.31px solid rgba(255, 255, 255, 1);
}

.para2 {
	width: 277px;
	height: 28px;
	left: 30px;
	font-family: Open Sans;
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	margin: 1px 0 0 2px;
}

.checkboxpara {
	display: flex;
	gap: 10px;
}

.continuebutton button {
	width: 100%;
	height: 40px;
	border-radius: 4px;
	border: 1px;
	background: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%),
		linear-gradient(0deg, #319bda, #319bda);
	color: white;
	border: 1px solid rgba(49, 155, 218, 1);
	font-size: 15px;
}

.gapdiv {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 40px;
}

.twoinputbox {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.inputbox input {
	padding-left: 20px;
}

.selectinput input {
	padding-left: 20px;
}

.lastLine div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Open Sans;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.span1 {
	font-size: 40px;
}

.span2 {
	color: rgba(49, 155, 218, 1);
	margin-left: 3px;
}

.span3 {
	position: relative;
	top: 8px;
}

/* .PhoneInput {
    

} */

.PhoneInputInput {
	width: calc(100% - 7vw);
	height: 37px;
	border-radius: 13px;
	border: 1px;
	background: linear-gradient(0deg, #ffffff, #ffffff);
	border: 1px solid rgba(226, 226, 226, 1);
	margin-left: 2px;
}

.selectinput .PhoneInputCountrySelect {
	width: 12vh;
	background: rgba(247, 247, 247, 1);
	border: 2px solid rgba(226, 226, 226, 1);
}

.PhoneInputCountry {
	border: 2px solid rgba(226, 226, 226, 1);
	border-radius: 13px;
	width: 7vh;
	padding: 0 0 0 10px;
}

.PhoneInputCountrySelectArrow {
	position: absolute;
	top: 30%;
	right: 10px;
	/* padding-left: 10px; */
}

.PhoneInputCountryIcon .PhoneInputCountryIcon--border {
	padding-right: 10px;
	background-color: white;
	box-shadow: none;
}

.lastLine .spanlast {
	display: flex;
	flex-direction: row;
}

.nameError {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	color: rgba(255, 0, 0, 1);
	position: absolute;
	top: 197px;
	left: 25px;
}

.emailError {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	color: rgba(255, 0, 0, 1);
	position: absolute;
	top: 259px;
	left: 25px;
}

.mobileError {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	color: rgba(255, 0, 0, 1);
	position: absolute;
	top: 320px;
	left: 110px;
}

.checkboxError {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	color: rgba(255, 0, 0, 1);
	position: absolute;
	top: 399px;
	left: 11%;
}

/* otp box css */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Work+Sans:wght@300&display=swap");

.otp_container {
	/* border: 1px solid red; */
	margin: 15px;
	margin-top: 30px;
	/* position: fixed; */
	/* height: 100vh;
    width: 100vw; */
}

.sdfs {
	display: flex;
	justify-content: space-between;
}

.sdfs :nth-child(2) {
	color: rgba(38, 138, 185, 1);
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
}

.flex_class_signup {
	display: flex;
	flex-direction: column;
	margin-top: 15%;
}

.auto-fill p {
	margin: 2px 0px;
}

.bootom_flex {
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-top: -7%;
}

.flex-gap-2 {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.otp_div {
	/* width: 134px !important; */
	height: 25px !important;
	font-family: Open Sans;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	width: 100% !important;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 70px;
}

.sdfs div {
	font-size: 14px;
}

.send_via_sms {
	font-family: Open Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
}

.auto-fill {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
}

.otpBox {
	width: 36px !important;
	height: 42px;
	border: 1px solid rgba(226, 226, 226, 1);
	border-radius: 13px;
	background: linear-gradient(0deg, #ffffff, #ffffff);
}

.otp-submit-signup {
	text-align: center;
	height: 35px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #319bda;
	background: linear-gradient(93deg, #127ebe 0.58%, #69c9ca 99.61%);
	width: 100%;
}

.flex_class .otp-submit-signup {
	color: white;
	width: 100%;
}

.otp-submit-signup .submit-otp-button-signup {
	color: white;
	width: 100%;
}

.secondbox .otp_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -40px;
}

.NeedSupport {
	width: 133px;
	height: 40px;
	border-radius: 20px;
	border: 1px;
	opacity: 0.5px;
	background: linear-gradient(0deg, #d9d9d9, #d9d9d9);
	border: 1px solid rgba(0, 0, 0, 0.5);
	font-family: Open Sans;

	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	/* margin-left: 100px; */
}

.bottomDiv-sign-otp {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 85%;
	left: 35%;
}
.signbottomDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.lowerparagraph {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 30px;
}

.upperdiv {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 30px;
}

.needSupportbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
}

.getstarted {
	color: #2a2a2a;
	font-family: Open Sans;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-left: 5px;
}

.accountnow {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.needsupportfont {
	color: #000;
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	opacity: 0.99;
	color: rgba(0, 0, 0, 1);
}

.otpsignTopupxheading {
	width: 102px;
	height: 39px;
	top: 77px;
	left: 25px;
	font-family: Poppins;
	font-size: 26px;
	font-weight: 500;
	line-height: 39px;
	letter-spacing: 0em;
	text-align: left;
	background-image: linear-gradient(93.37deg, #127ebe 0.58%, #69c9ca 99.61%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
